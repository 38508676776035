<template>
  <div v-if="list_visibility" class="">
    <!-- table to be printed -->
    <section class="hidden">
      <div id="member-print" class="bg-white mx-auto" style="width: 210mm">
        <div class="mb-6">
          <img
            class="w-12 inline-block mr-2 align-top"
            src="@/assets/logo.png"
            alt=""
          />
          <p class="inline-block text-left">
            <span class="block">SDIT ALMADINAH PONTIANAK</span>
            <span class="text-gray-500">DAFTAR PESERTA SELEKSI</span>
          </p>
        </div>
        <p>
          <span class="inline-block w-20">Mulai</span>
          <span class="inline-block ml-2">{{
            selected_schedule.started_at | indonesian_datetime_format
          }}</span>
        </p>
        <p>
          <span class="inline-block w-20">Selesai</span>
          <span class="inline-block ml-2">{{
            selected_schedule.ended_at | indonesian_datetime_format
          }}</span>
        </p>
        <p>
          <span class="inline-block w-20">Ruangan</span>
          <span class="inline-block ml-2">{{
            selected_schedule.location
          }}</span>
        </p>
        <table class="border-t border-b border-black w-full mt-6">
          <tr class="border-b border-black">
            <th class="w-10 px-4 py-2">No.</th>
            <th class="text-left">Nama</th>
            <th class="text-left">Kontak</th>
            <th class="text-left">Waktu Seleksi</th>
          </tr>
          <tr v-for="(item, key) in members" :key="`print-member-${key}`">
            <td class="text-center px-4 py-2">{{ key + 1 }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.phone }}</td>
            <td>
              {{ item.period_schedule_started_at | time_only }} -
              {{ item.period_schedule_ended_at | time_only }}
            </td>
          </tr>
        </table>
      </div>
    </section>

    <div class="mb-4">
      <aside>
        <button class="inline-block p-2 text-xl mr-2" @click="hide()">
          <fa-icon icon="angle-up"></fa-icon>
        </button>

        <span class="text-xl mb-6 uppercase tracking-wide">
          Anggota Kelompok Tes
        </span>
      </aside>
    </div>

    <!-- member list -->
    <div class="p-2 text-center uppercase tracking-wider" v-if="!hasMember">
      Belum ada anggota kelompok
    </div>
    <button class="button --primary mb-4" @click="triggerPrint()" v-else>
      <fa-icon icon="print"></fa-icon>
      <span class="ml-2">Cetak</span>
    </button>
    <div class="sm:grid sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
      <member-list-item
        v-for="(member, key) in members"
        :key="key"
        :member="member"
      ></member-list-item>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/event-bus.js";
import html2pdf from "html2pdf.js";

export default {
  methods: {
    hide() {
      this.UNSET_SELECTED_SCHEDULE();
      this.HIDE_LIST();

      // show previously hidden period items
      EventBus.$emit("schedule.item.show");
    },
    triggerPrint() {
      const element = document.getElementById("member-print");

      const opt = {
        margin: 4,
        filename: `peserta-tes.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2, scrollX: 0, scrollY: 0 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        .then(function (pdfObj) {
          // pdfObj has your jsPDF object in it, use it as you please!
          // For instance (untested):
          pdfObj.autoPrint();
          window.open(pdfObj.output("bloburl"), "_blank");
        });
    },
    ...mapMutations("member", ["HIDE_LIST"]),
    ...mapMutations("schedule", ["UNSET_SELECTED_SCHEDULE"]),
  },
  computed: {
    hasMember() {
      return this.members.length > 0;
    },
    ...mapGetters("member", ["list_visibility", "members"]),
    ...mapGetters("schedule", ["selected_schedule"]),
  },
  name: "MemberList",
  components: {
    MemberListItem: () =>
      import("@/components/Registration/Member/MemberListItem.vue"),
  },
};
</script>